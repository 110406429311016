import './index.scss'

window.onload = () => {

  const [label] = [location.hash, location.pathname].map(v =>
    v.slice(1)
      .replace(/^(http:|https:)?\/*/i, '')
      .replace(/^(t\.me)?\/*/i, '')).filter(Boolean);

  if (label) {

    const button = document.querySelector('.tg');
    button.href = 'http://t.me/' + label;
    const path = button.pathname.split('/', 3);
    let str = '';

    switch (path[1]) {
      case 'socks':
        str = 'tg://socks' + button.search;
        break;
      case 'share':
        str = 'tg://msg_' + path[2] + button.search;
        break;
      case 'joinchat':
        str = 'tg://join?invite=' + path[2];
        break;
      case 'addstickers':
        str = 'tg://addstickers?set=' + path[2];
        break;
      case 'proxy':
        str = 'tg://proxy' + button.search;
        break;
      default:
        str = 'tg://resolve?domain=' + path[1] + button.search.replace('?start=', '&start=');
        if (path[2]) {
          str += '&post=' + path[2];
        }
    }

    button.href = str;

    setTimeout(() => {
      location.href = str;
    }, 3000)
  }
};
